<template>
  <div class="card">
    <div class="card-body">
      <div v-if="section" class="overflow-hidden pb-3">
        <div class="badge rounded-pill bg-dark float-left">{{ section.name }}</div>
      </div>

      <div class="d-flex align-items-center">
        <ExerciseBadge :exercise="exercise" :exercises="exercises" class="me-3"></ExerciseBadge>
        <div class="flex-grow-1">
          <div class="fs-5 lh-sm">{{ exercise.name }}</div>
          <TextView
            v-if="exercise.description"
            :text="exercise.description"
            class="smaller text-muted mt-1"
          ></TextView>
        </div>
        <IconButton
          @click="showExerciseHistoryModal = true"
          :icon="'history'"
          class="btn ms-3"
        ></IconButton>
      </div>

      <ExerciseSetRep
        :sets="$options.filters.exerciseSetParsed(exercise)"
        class="mt-3 pt-3 border-top fs-2 fw-light lh-sm"
      ></ExerciseSetRep>

      <div v-if="exercise.metricId" class="d-flex align-items-center mt-3 pt-3 border-top">
        <div class="flex-grow-1 me-3">
          <fa-icon :icon="['fas', 'chart-line']"></fa-icon>
          {{ exercise.metricName_ }} ({{ exercise.metricUnit_ }})
        </div>
        <SubmitButton
          @click="showExerciseMetricModal = true"
          :text="'Update'"
          class="btn btn-primary"
        ></SubmitButton>
      </div>

      <div v-if="videos.length" class="mt-3 pt-3 border-top">
        <VideoPlayer
          v-for="(video, videoIndex) in videos"
          :key="`key-${videoIndex}`"
          :video="video"
          :class="videoIndex > 0 ? 'mt-3' : ''"
        ></VideoPlayer>
      </div>

      <div class="mt-3 pt-3 border-top d-flex">
        <div class="form-floating flex-grow-1">
          <textarea
            v-model.trim="note"
            ref="note"
            id="note"
            class="form-control"
            rows="3"
            placeholder="Results and notes"
          ></textarea>
          <label for="note" class="form-label">Results and notes</label>
        </div>
        <SubmitButton
          @click="saveNote"
          v-if="isNoteDisabled"
          :text="'Save'"
          :isLoading="isSavingNote"
          :disabled="isSavingNote"
          class="btn btn-primary ms-2"
        ></SubmitButton>
      </div>

      <div class="mt-3 pt-3 border-top d-flex justify-content-center">
        <ExerciseStateButton
          @click="saveCompletion(false)"
          :forState="'missed'"
          :isActive="isMissed"
          :isLoading="isMissing"
          :class="{ 'is-busy': isMissing || isCompleting }"
          class="mx-3"
        ></ExerciseStateButton>
        <ExerciseStateButton
          @click="saveCompletion(true)"
          :forState="'completed'"
          :isActive="isCompleted"
          :isLoading="isCompleting"
          :class="{ 'is-busy': isCompleting || isMissing }"
          class="mx-3"
        ></ExerciseStateButton>
      </div>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ExerciseHistoryModal
          @close="showExerciseHistoryModal = false"
          v-if="showExerciseHistoryModal"
          v-model="showExerciseHistoryModal"
          :exercise="exercise"
        ></ExerciseHistoryModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <MetricNewEntryModal
          @close="showExerciseMetricModal = false"
          v-if="showExerciseMetricModal"
          v-model="showExerciseMetricModal"
          :userId="authUser.userId"
          :metricId="exercise.metricId"
        ></MetricNewEntryModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'WorkoutExerciseSliderCard',
  components: {
    ExerciseBadge: () => import('@/components/ExerciseBadge'),
    TextView: () => import('@/components/TextView'),
    ExerciseSetRep: () => import('@/components/ExerciseSetRep'),
    VideoPlayer: () => import('@/components/VideoPlayer'),
    ExerciseStateButton: () => import('@/components/button/ExerciseStateButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    IconButton: () => import('@/components/button/IconButton'),
    ExerciseHistoryModal: () => import('@/components/modal/ExerciseHistoryModal'),
    MetricNewEntryModal: () => import('@/components/modal/MetricNewEntryModal'),
  },
  props: {
    section: {
      type: Object,
      default: null,
    },
    exercise: {
      type: Object,
      required: true,
    },
    exercises: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('workout', ['workoutExerciseStatuses']),
    ...mapGetters('workout', ['workoutIsStarted', 'workoutIsFinished', 'workoutUnmarkedExercises']),
    videos() {
      const { resource } = this.exercise;
      try {
        return resource ? JSON.parse(resource) : [];
      } catch {
        return [];
      }
    },
    exerciseStatus() {
      const { exercise, workoutExerciseStatuses } = this;
      return workoutExerciseStatuses.find((status) => status.exerciseId === exercise.exerciseId);
    },
    isCompleted() {
      if (!this.exerciseStatus || this.exerciseStatus.isCompleted === null) return null;
      return !!this.exerciseStatus.isCompleted === true;
    },
    isMissed() {
      if (!this.exerciseStatus || this.exerciseStatus.isCompleted === null) return null;
      return !!this.exerciseStatus.isCompleted === false;
    },
    isNoteDisabled() {
      const { exerciseStatus, note } = this;
      if (exerciseStatus) {
        return exerciseStatus.note !== note;
      }
      return note && note !== '';
    },
  },
  methods: {
    async saveCompletion(bool) {
      if (bool) {
        this.isCompleting = true;
      } else {
        this.isMissing = true;
      }

      try {
        const { sessionId, exerciseId } = this.exercise;
        const { userId } = this.authUser;

        // If workout has not been started, start the workout.
        if (!this.workoutIsStarted) {
          await this.$store.dispatch('workout/startWorkout', { sessionId, athleteId: userId });
        }

        await this.$store.dispatch('workout/setExerciseCompletion', {
          sessionId,
          exerciseId,
          userId,
          isCompleted: bool,
          note: this.note,
        });

        // If all exercises are marked and the workout has not been finished, finish the workout.
        if (!this.workoutIsFinished && !this.workoutUnmarkedExercises.length) {
          await this.$store.dispatch('workout/finishWorkout', { sessionId, athleteId: userId });
        }
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isCompleting = false;
        this.isMissing = false;
      }
    },
    async saveNote() {
      this.isSavingNote = true;
      try {
        const { sessionId, exerciseId } = this.exercise;
        const { userId } = this.authUser;
        const { note } = this;
        await this.$store.dispatch('workout/setExerciseNote', {
          sessionId,
          exerciseId,
          userId,
          note,
        });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSavingNote = false;
      }
    },
  },
  data() {
    return {
      isMissing: false,
      isCompleting: false,
      isSavingNote: false,
      showExerciseHistoryModal: false,
      showExerciseMetricModal: false,
      note: null,
    };
  },
  mounted() {
    if (this.exerciseStatus) {
      this.note = this.exerciseStatus.note;
    }
  },
};
</script>
